@font-face {
  font-family: 'Special Elite';
  src: url('./fonts/SpecialElite-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  font-family: 'Special Elite', system-ui;
  padding: 20px;
  box-sizing: border-box;
  transition: padding 0.3s ease;
}

.image-container {
  box-sizing: border-box;
  padding: 2em;
  flex: 0 0 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 30vh;
  transition: height 0.3s ease, flex-basis 0.3s ease;
}

.image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.notification-box {
  position: absolute;
  transform: translate(-50%, -50%);
  background-color: black;
  color: #FCF5E5;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  z-index: 1000;
  pointer-events: none
}

.header-container {
  flex: 0 0 auto;
  text-align: center;
}

.header {
  margin: 1;
}

.text-container {
  flex: 1 1 60%;
  width: 50%;
  padding-bottom: 20px;
  font-size: 20px;
  font-weight: bold;
  text-align: left;
  letter-spacing: 1.5px;
  line-height: 1.5;
  word-wrap: break-word;
  word-break: break-all;
  transition: width 0.3s ease, font-size 0.3s ease;
}

/* Media Queries for Mobile Optimization */
@media (max-width: 768px) {
  .App {
    padding: 10px;
  }

  .image-container {
    box-sizing: border-box;
    flex: 0 0 30%;
    height: 20vh;
  }

  .text-container {
    flex: 1 1 70%;
    width: 80%;
    font-size: 18px;
  }
}

@media (max-width: 480px) {
  .App {
    padding: 5px;
  }

  .text-container {
    width: 90%;
    font-size: 16px;
  }
}
